<template>
    <div :class="['card', autoWidth ? 'card-block': '']">

        <div class="card-content">
            <card-share-button class="float-right" :link="linkTo"></card-share-button>
            <h3 class="title">{{ item.name }}</h3>
            <p class="desc do-not-translate" v-if="institutions!==null && institutions.length>0" data-wg-notranslate="">{{ institutionTitles }}</p>

        </div>
        <router-link :to="linkTo" :title="linkToTitle" :alt="linkToTitle" class="btn btn-hover btn-more mt-auto card-hover">
            Daha fazla bilgi
            <span class="material-icons-outlined show-ltr" aria-hidden="true" data-wg-notranslate="">navigate_next</span>
            <span class="material-icons-outlined show-rtl" aria-hidden="true" data-wg-notranslate="">navigate_before</span>
        </router-link>
    </div>
</template>

<script>
    import CardShareButton from '@/components/controls/CardShareButton.vue';

    export default {
        name: 'MerklisteCard',
        components: {
            CardShareButton,
        },
        props: {
            item: {
                type: Object,
                required: true
            },
            autoWidth: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            linkTo() {
                return "/merkliste/" + this.item.id;
            },
            linkToTitle() {
                return "Mehr Informationen zu: " + this.item.name;
            },
            contents() {
                return this.item.contents;
            },
            institutions() {
                if (this.contents !== null) {
                    var institutions = [];
                    for (var i = 0; i < this.contents.length; i++) {
                        var content = this.contents[i];
                        if (content.institution_title !== null) {
                            institutions.push(content.institution_title);
                        }
                    }
                    var uniqueInstitutions = [...new Set(institutions)];
                    return uniqueInstitutions;
                }
                return null;
            },
            institutionTitles() {
                if (this.institutions.length > 0) {
                    var title = this.institutions.join(", ");
                    title = title.length > 150 ? title.substring(0, 150) + ' ...' : title;
                    return title;
                }
                return null;
            }
        }
    }
</script>

<style lang="scss" scoped="">
    @import '../../scss/_variables.scss';

    .card {
        margin-left: 15px;
        display: flex;
        position: relative;
        min-width: 290px;
        max-width: 324px;
        border: none;
        border-radius: 0px;
        margin-right: 15px;
        overflow: visible;
        margin-top: 20px;
        box-shadow: 1px 2px 10px 1px #00000040;

        &-img {
            position: absolute;
            width: 65px;
            height: 65px;
            border-radius: 100px;
            background-color: $white-color;
            border: 1px solid $primary;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -25px;
            left: 25px;

            img {
                max-width: 40px;
                max-height: 41px;
            }
        }

        &-content {
            padding: 20px;

            .category {
                color: $primary;
                margin-top: 15px;
                margin-bottom: 20px;
                font-size: 14px;
            }

            .time {
                color: $text-light;
            }

            .title {
                font-size: 16px;
                line-height: 24px;
                margin-top: 6px;
                margin-bottom: 12px;
                height: 50px;
            }

            .image {
                img {
                    max-height: 80px;
                }
            }

            .desc {
                height: 68px;
                line-height: 20px;
                font-size: 14px;
                margin-bottom: 10px;
            }

            &__list {
                display: block;
                list-style: none;

                li {
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 5px;
                    color: $black-color;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .icon {
                        span {
                            font-size: 18px;
                            margin-right: 15px;
                            line-height: 24px;
                        }
                    }

                    .info {
                        display: inline-flex;
                        flex-direction: column;
                    }
                }
            }
        }

        &:first-child {
            margin-left: 15px;
        }

        &-block {
            min-width: 290px;
            max-width: 100%;
            width: 100%;
            margin-right: 0;

            &:first-child {
                margin-left: 0;
            }
        }

        &.no-slider {
            margin-right: 0;
            margin-left: 15px;
        }
    }

    body[dir="rtl"] {
        .card {
            margin-right: 0;
            margin-left: 15px;

            .card-img {
                left: auto;
                right: 25px;
            }

            .card-content__list {
                li {
                    .icon {
                        span {
                            margin-left: 15px;
                            margin-right: 0;
                        }
                    }
                }
            }

            &.card-block {
                margin-left: 0;

                &:first-child {
                    margin-right: 0;
                }
            }
        }
    }

</style>
